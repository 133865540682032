import React, { useContext } from "react"
import Container from "./Container"

import CtaBtn from "./CtaBtn"

import { ModalContext } from "./layout"

const CtaBanner = () => {
  const { openFormModal } = useContext(ModalContext)
  return (
    <section className="cta-banner">
      <Container>
        <h2 className="cta-banner__title cta-btn--dark">Zainteresowany?</h2>
        <CtaBtn
          text="Zapisz się na kurs"
          _classes="cta-banner__button cta-btn--dark"
          action={openFormModal}
        />
      </Container>
    </section>
  )
}

export default CtaBanner
