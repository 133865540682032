export const navigatinLinks = [
  {
    id: "l01",
    content: "Home",
    link: "/",
  },
  {
    id: "l02",
    content: "O nas",
    link: "/o-nas",
  },
  {
    id: "l03",
    content: "Cennik",
    link: "/#cennik",
  },
  {
    id: "l04",
    content: "Szkolenia",
    link: "/szkolenia",
  },
  {
    id: "l05",
    content: "Terminarz",
    link: "/#terminarz",
  },
  {
    id: "l06",
    content: "Nauka znaków",
    link: "/szkolenia/#znaki",
  },
  {
    id: "l07",
    content: "Kontakt",
    link: "/kontakt",
  },
]
