import React from "react"

import Arrow from '../images/icons/arrow.svg'

const CtaBtn = ({text, action, type='button',  _classes, _label}) => {
  return (
    <button
      className={`cta-btn${_classes ? ` ${_classes}` : ''}`}
      onClick={action}
      type={type}
      aria-label={_label ? _label : text}
>
      {text}
      <div className="cta-btn__inner">
        <Arrow />
      </div>
    </button>
  )
}

export default CtaBtn
