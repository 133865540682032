import React, { useContext, useRef, useState } from "react"
import { useRemarkSync } from "react-remark"
import { StaticImage } from "gatsby-plugin-image"

import CtaBtn from "./CtaBtn"
import { ModalContext } from "./layout"

const Modal = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const { closeModal, visible, content, openFormModal } =
    useContext(ModalContext)
  const modalRef = useRef(null)

  const description = useRemarkSync(content.context)

  const onCLose = () => {
    setCurrentStep(0)
    return closeModal()
  }

  const hideModal = e => {
    if (e.target !== modalRef.current) return
    return onCLose()
  }

  const next = () => {
    if (currentStep + 1 === content.context.length) return
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    if (currentStep === 0) return
    setCurrentStep(currentStep - 1)
  }

  if (content.context && content.context.length && typeof content.context === 'object')
    return (
      <div
        className={`modal d-flex align-items-center justify-content-center${
          visible ? " modal--visible" : ""
        }`}
        onClick={hideModal}
        ref={modalRef}
        onKeyPress={e => {
          if (e.code !== 13) return
          return hideModal()
        }}
        role="button"
        tabIndex={0}
      >
        <div className="modal__container column justify-content-between">
          <div className="d-flex justify-content-end">
            <button className="modal__btn-close" onClick={onCLose}>
              <div className="btn-close__inner column align-items-center justify-content-center"></div>
            </button>
          </div>

          <div className="modal__content">
            <div className="modal__headers d-flex">
              <div className="modal__picture">
                <img
                  src={content.context[currentStep].image}
                  alt={content.context[currentStep].titile}
                />
              </div>
              <div className="modal__titles">
                <h2 className="modal__title">
                  {content.context[currentStep].title}
                </h2>
                <h3 className="modal__subtitle">
                  {content.context[currentStep].subtitle}
                </h3>
              </div>
            </div>

            {content.context[currentStep].context}
          </div>

          <div className="d-flex align-items-center justify-content-between modal__controllers">
            <CtaBtn text="Cofnij" _classes="cta-btn--no-arrow" action={prev} />
            <p>
              {currentStep + 1}/{content.context.length}
            </p>
            <CtaBtn text="Dalej" _classes="cta-btn--no-arrow" action={next} />
          </div>
        </div>
      </div>
    )

  return (
    <div
      className={`modal d-flex align-items-center justify-content-center${
        visible ? " modal--visible" : ""
      }`}
      onClick={hideModal}
      ref={modalRef}
      onKeyPress={e => {
        if (e.code !== 13) return
        return hideModal()
      }}
      role="button"
      tabIndex={0}
    >
      <div className="modal__container column justify-content-between">
        <div className="d-flex justify-content-end">
          <button className="modal__btn-close" onClick={closeModal}>
            <div className="btn-close__inner column align-items-center justify-content-center"></div>
          </button>
        </div>

        <div className="modal__content">
          <div className="modal__headers">
            <h2 className="modal__title">{content.title}</h2>
            <h3 className="modal__subtitle">{content.subtitle}</h3>
          </div>

          {description}

          <p
            className={`modal__postscript${
              content.postScriptOldAmount ? " modal__postscript--updated" : ""
            }`}
          >
            <span className="modal__postscript--bold">
              {content.postscript}
            </span>
            : {content.postScriptAmount}
            <del className="modal__postscript-old">
              {content.postScriptOldAmount}
            </del>
          </p>

          <div className="modal__icon">
            <StaticImage src="../images/car.png" alt="auto" />
          </div>
        </div>

        <div className="d-flex justify-content-end modal__controllers">
          <CtaBtn
            text="Zapisz się teraz"
            _classes="cta-btn--dark"
            action={() => {
              closeModal()
              openFormModal()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Modal
