import React from "react"
import Layout from "./src/components/layout"
import { createClient, Provider } from "urql"
import emailjs from "@emailjs/browser"
import Messenger from "./src/components/Messenger"

emailjs.init(process.env.GATSBY_EMAILJS_ID)

const client = createClient({
  url: process.env.GATSBY_GRAPHCMS_ENDPOINT ?? "",
})

export const wrapPageElement = ({ element, props }) => {
  return (
    <Provider value={client}>
      <Layout {...props}>
        {element}
        <Messenger />
      </Layout>
    </Provider>
  )
}
