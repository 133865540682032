import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Topbar from "./Topbar"
import Footer from "./Footer"
import CtaBanner from "../components/CtaBanner"
import Modal from "../components/Modal"
import FormModal from "../components/FormModal"
import "../scss/main.scss"
import { useQuery } from "urql"
import AOS from "aos"
import "aos/dist/aos.css"

export const ModalContext = React.createContext({
  visible: false,
  formVisible: false,
  openModal: null,
  closeModal: null,
  openFormModal: null,
  closeFormModal: null,
  content: null,
})

const getData = `
  query {
    registrations {
      date
      postscript
      isFull
      id
    }
  offers {
          id
          title
          subtitle
          postscript
          postScriptOldAmount
          postScriptAmount
          context
          order
        }
  }
  
  `

const Layout = ({ children, path }) => {
  const [result] = useQuery({ query: getData })
  const { data } = result

  const [modalVisibility, setModalVisibility] = useState(false)
  const [formModalVisibility, setFormModalVisibility] = useState(false)
  const [formModalDate, setFormModalDate] = useState(null)
  const [modalContent, setModalContent] = useState(false)

  const openModal = () => setModalVisibility(true)
  const closeModal = () => setModalVisibility(false)

  const openFormModal = _date => {
    setFormModalVisibility(true)
    _date ? setFormModalDate(_date) : setFormModalDate(null)
  }
  const closeFormModal = () => setFormModalVisibility(false)

  const setUpModalContent = value => setModalContent(value)
  const clearModalContent = () => setModalContent(null)

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    })
    AOS.refresh()
  }, [])

  return (
    <ModalContext.Provider
      value={{
        visible: modalVisibility,
        formVisible: formModalVisibility,
        openModal,
        closeModal,
        clearModalContent,
        setUpModalContent,
        closeFormModal,
        openFormModal,
        content: modalContent,
        data,
      }}
    >
      <Topbar />
      <FormModal modalDate={formModalDate} />
      <Modal />
      <>{children}</>
      <CtaBanner />
      <Footer />
    </ModalContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
