import React, { useEffect } from "react"

const Messenger = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: "v12.0",
        autoLogAppEvents : true,
      })
    }

    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  })

  return (
    <>
      <div id="fb-root"></div>

      <div
        id="fb-customer-chat"
        className="fb-customerchat"
        attribution="biz_inbox"
        page_id="545423618801013"
      ></div>
    </>
  )
}

export default Messenger
