import React from 'react'

const Container = ({children, _classes}) => {
  return (
    <div className={`container mx-auto${_classes ? ' ' + _classes : ''}`}>
      {children}
    </div>
  )
}

export default Container
