const emailRegex = /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9](?:[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9-]{0,61}[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9])?(?:\.[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9](?:[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9-]{0,61}[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9])?)*$/

export const formSetup = [
  {
    id: "name",
    name: "name",
    type: "text",
    label: "Imię i nazwisko",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6 col-lg-4",
  },
  {
    id: "email",
    name: "email",
    type: "email",
    label: "E-mail",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6 col-lg-4",
  },
  {
    id: "phone",
    name: "phone",
    type: "phone",
    label: "Telefon",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6 col-lg-4",
  },
  {
    id: "pesel",
    name: "pesel",
    type: "number",
    label: "PESEL",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6",
  },
  {
    id: "date",
    name: "date",
    type: "select",
    label: "Termin",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-12 col-lg-6",
  },
  {
    id: "category",
    name: "category",
    type: "select",
    label: "Rodzaj kursu",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-12",
  },
  {
    id: "message",
    name: "message",
    type: "textarea",
    label: "Uwagi",
    required: false,
    value: "",
    error: "",
    _classes: "col-12",
  },
]

export const contactFormSetup = [
  {
    id: "name",
    name: "name",
    type: "text",
    label: "Imię i nazwisko",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6 col-lg-4",
  },
  {
    id: "email",
    name: "email",
    type: "email",
    label: "E-mail",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6 col-lg-4",
  },
  {
    id: "phone",
    name: "phone",
    type: "phone",
    label: "Telefon",
    required: true,
    value: "",
    error: "Untouched",
    _classes: "col-12 col-sm-6 col-lg-4",
  },
  {
    id: "title",
    name: "title",
    type: "text",
    label: "Tytuł",
    required: false,
    value: "",
    error: "",
    _classes: "col-12 col-sm-6 col-lg-12",
  },
  {
    id: "message",
    name: "message",
    type: "textarea",
    label: "Wiadomość",
    required: false,
    value: "",
    error: "",
    _classes: "col-12",
  },
]

export const validation = (id, value, touched, required, setError) => {
  if (touched && required && !value) return setError(id, "Uzupełnij pole.")

  if (touched && id === "name" && !value.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ -]+$/))
    return setError(id, "Niedozwolone znaki.")

  if (touched && id === "name" && value.trim().length < 3)
    return setError(id, "Pole wymaga 3 znaków.")

  if (touched && id === "title" && value.trim().length < 3)
    return setError(id, "Pole wymaga 3 znaków.")

  if (
    touched &&
    id === "email" &&
    !emailRegex.test(value)
  )
    return setError(id, "E-mail jest nieprawidłowy.")

  if (touched && id === "phone" && value.trim().length < 9)
    return setError(id, "Numer jest za krótki.")

  if (touched && id === "phone" && value.trim().length > 9)
    return setError(id, "Numer jest za długi.")

  if (touched && id === "phone" && !/^[0-9]{9}$/.test(value))
    return setError(id, "Numer jest nieprawidłowy.")

  if (touched && id === "pesel" && value.trim().length !== 11)
    return setError(id, "PESEL jest nieprawidłowy.")

  return setError(id, "")
}
