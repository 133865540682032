import React, { useState, useContext, useEffect } from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import CtaBtn from "./CtaBtn"
import Container from "./Container"
import { navigatinLinks } from "../links"

import { ModalContext } from "./layout"

const Topbar = () => {
  const { openFormModal } = useContext(ModalContext)
  const [navToggler, setNavToggler] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const toggleNav = _value => {
    setNavToggler(_value === false ? _value : !navToggler)
  }

  const navigationItems = navigatinLinks.map(item => (
    <li className="nav__item" key={item.id}>
      <Link className="nav__link" to={item.link} onClick={toggleNav}>
        {item.content}
      </Link>
    </li>
  ))

  const isScrolled = () =>
    window.scrollY > 50 ? setScrolled(true) : setScrolled(false)

  useEffect(() => {
    if (!window) return
    window.addEventListener("scroll", isScrolled)

    return () => {
      window.removeEventListener("scroll", isScrolled)
    }
  }, [window])

  return (
    <div
      className={`topbar${navToggler ? " topbar--active" : ""}${
        scrolled ? " topbar--scrolled" : ""
      }`}
    >
      <Container _classes="d-flex align-items-center justify-content-between">
        <div className="topbar__brand">
          <Link to="/" className="brand__link" onClick={() => toggleNav(false)}>
            <StaticImage
              src="../images/adept-logo.png"
              alt="Szkoła jazdy Adept"
              placeholder="blurred"
              layout="constrained"
              formats={["auto", "webp"]}
              width={150}
              height={75}
            />
          </Link>
        </div>
        <button
          className="topbar__nav-btn"
          onClick={toggleNav}
          aria-label="Otwórz menu"
        >
          <div className="nav-btn__inner"></div>
        </button>
        <nav className="topbar__nav">
          <ul className="nav__list">
            {navigationItems}
            <li className="nav__item">
              <CtaBtn
                text="Zapisz sie teraz"
                action={() => {
                  openFormModal()
                  setTimeout(toggleNav, 300)
                }}
                _classes="nav__link nav__link--decorated"
              />
            </li>
          </ul>
        </nav>
      </Container>
    </div>
  )
}

export default Topbar
